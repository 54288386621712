import { createRoot } from 'react-dom/client';
import { FC } from 'react';

import config from 'config';

import ActualApp from './__actual/App';

// AQA needs
window.HIDE_FRONT_CHAT = Number(config.HIDE_FRONT_CHAT);

const root = createRoot(document.getElementById('root') as HTMLElement);

const ProjectSwitcher: FC = () => {
    return <ActualApp />;
};

root.render(<ProjectSwitcher />);
