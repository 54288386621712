import { SUBSCRIPTION_STATES } from 'components/SubscriptionsTab/components/SubscriptionCard/constants';
import { ISubscription } from 'types/subscription';

const activeSubscriptionState = [
    SUBSCRIPTION_STATES.ACTIVE,
    SUBSCRIPTION_STATES.REDEMPTION,
    SUBSCRIPTION_STATES.PAUSED,
];

const canceledSubscriptionState = [SUBSCRIPTION_STATES.CANCELLED];

type UseSubscriptionsCountRes = {
    activeCount: number;
    canceledCount: number;
};

const useSubscriptionsCount = (subscriptions: ISubscription[] = []): UseSubscriptionsCountRes => {
    const { activeCount, canceledCount } = subscriptions.reduce(
        (acc, s) => {
            if (
                activeSubscriptionState.includes(s.state) &&
                !s.cancelled_at &&
                !(s.internal_cancel_at && s.state === SUBSCRIPTION_STATES.ACTIVE)
            ) {
                acc.activeCount += 1;
            }

            if (
                canceledSubscriptionState.includes(s.state) ||
                s.cancelled_at ||
                (s.internal_cancel_at && s.state === SUBSCRIPTION_STATES.ACTIVE)
            ) {
                acc.canceledCount += 1;
            }

            return acc;
        },
        {
            activeCount: 0,
            canceledCount: 0,
        }
    );

    return {
        activeCount,
        canceledCount,
    };
};

export { useSubscriptionsCount };
