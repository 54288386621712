import { Text } from 'wikr-core-components';
import React from 'react';

import * as S from '../styled';

import { IAdditionalStatusInfo } from '../../types';

const AdditionalStatusInfo = ({ captions, mb }: IAdditionalStatusInfo) => {
    return (
        <S.AdditionalStatusInfo mb={mb}>
            {captions?.title && <Text text={captions.title} mb={8} />}
            <Text text={captions?.description} color="text-secondary" />
        </S.AdditionalStatusInfo>
    );
};

export default AdditionalStatusInfo;
