import { useSelector } from 'react-redux';

import { selectCurrentUser } from 'store/user/selectors';

import { LANG_TO_LOCALE_MAP } from 'constants/localization';

import { getDeviceInfo } from 'helpers/utils';

export const useGetCFLink = () => {
    const { os, browser } = getDeviceInfo();
    const { name, email, user_id, language } = useSelector(selectCurrentUser);
    const paymentPlatfrom = user_id ? 'web' : '';

    const [nameWithFallback, idWithFallback, emailWithFallback] = [name ?? '', user_id ?? '', email ?? ''];
    const locale = LANG_TO_LOCALE_MAP[language ?? 'en'];

    return `https://contact-us.welltech.com/musclebooster.html?locale=${locale}&name=${nameWithFallback}&user_id=${idWithFallback}&email=${emailWithFallback}&os_version=${os}&model=${browser}&payment_platform=${paymentPlatfrom}&source=web-cabinet`;
};
