import { useTranslation } from 'react-i18next';
import React from 'react';

import { ICancelButton } from '../../types';
import OutlinedButton from 'components/OutlinedButton';

const CancelButton = ({ onCancel }: ICancelButton) => {
    const { t } = useTranslation();

    return (
        <OutlinedButton onClick={onCancel} data-locator="cancelRenewalButton" text={t('subscription.text.cancel')} />
    );
};

export default CancelButton;
