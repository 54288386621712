export const SUBSCRIPTION_STATES = {
    ACTIVE: 'active',
    PAUSED: 'paused',
    PENDING: 'pending',
    PENDING_FAILED: 'pending_failed',
    PAYMENT_ISSUE: 'payment_issue',
    REDEMPTION: 'redemption',
    CANCELLED: 'cancelled',
};

export const SUBSCRIPTION_LABEL_STATES = {
    ACTIVE: 'active',
    PAUSED: 'paused',
    PENDING: 'pending',
    PAYMENT_ISSUE: 'paymentIssue',
    UPCOMING: 'upcoming',
    UPDATING: 'updating',
    EXPIRES_SOON: 'expiresSoon',
    CANCELED: 'canceled',
    FREE_PERIOD: 'freePeriod',
    DISCOUNTED: 'discounted',
    REMINDER: 'reminder',
    EXPIRED: 'expired',
};

export const SECONDS_TO_SHOW_UPDATING_STATUS = 30;

export const SUBSCRIPTION_PAUSE_PERIOD = {
    count: 1,
    context: 'month',
};
