import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { SUBSCRIPTION_LABEL_STATES } from '../../constants';

import { Alarm, Alert, CheckCircle, SubPause } from 'components/Icon';

import * as S from '../styled';

import { ISubscriptionLabelState } from '../../types';

const SubscriptionStatusLabel = ({ state }: ISubscriptionLabelState) => {
    const { t } = useTranslation();

    return (
        <S.Status color={getColor(state)} data-locator="subscriptionStatus">
            {getLabelIcon(state)}
            <Text
                type="small-text"
                color={SUBSCRIPTION_LABEL_STATES.PAUSED === state ? 'surface-secondary' : 'text-main'}
                medium
                text={t(`subscription.status.${state}`)}
            />
        </S.Status>
    );
};

export default SubscriptionStatusLabel;

const getLabelIcon = (state: string) => {
    switch (state) {
        case SUBSCRIPTION_LABEL_STATES.ACTIVE:
            return <CheckCircle color="text-main" />;

        case SUBSCRIPTION_LABEL_STATES.PAUSED:
            return <SubPause color="surface-secondary" />;

        case SUBSCRIPTION_LABEL_STATES.PAYMENT_ISSUE:
            return <Alert color="on-primary" />;

        case SUBSCRIPTION_LABEL_STATES.UPCOMING:
        case SUBSCRIPTION_LABEL_STATES.PENDING:
        case SUBSCRIPTION_LABEL_STATES.REMINDER:
        case SUBSCRIPTION_LABEL_STATES.UPDATING:
            return <Alarm />;

        default:
            return null;
    }
};

const getColor = (state: string) => {
    switch (state) {
        case SUBSCRIPTION_LABEL_STATES.UPCOMING:

        case SUBSCRIPTION_LABEL_STATES.UPDATING:

        case SUBSCRIPTION_LABEL_STATES.EXPIRED:

        case SUBSCRIPTION_LABEL_STATES.PENDING:

        case SUBSCRIPTION_LABEL_STATES.REMINDER:

        case SUBSCRIPTION_LABEL_STATES.EXPIRES_SOON:
        case SUBSCRIPTION_LABEL_STATES.CANCELED:
            return 'gray';

        case SUBSCRIPTION_LABEL_STATES.PAUSED:
            return 'yellow';

        case SUBSCRIPTION_LABEL_STATES.PAYMENT_ISSUE:
            return 'red';

        case SUBSCRIPTION_LABEL_STATES.DISCOUNTED:
        case SUBSCRIPTION_LABEL_STATES.FREE_PERIOD:
            return 'green';

        default:
            return 'blue';
    }
};
